<template>
  <y_layout>
    <div class="flex-def flex-zCenter" style="width: 100%;min-width: 80rem">
      <el-tabs v-model="active" tab-position="left" type="border-card">
        <el-tab-pane label="系统更新" name="/manager/sys"></el-tab-pane>
        <el-tab-pane label="平台管理" name="/manager/uni"></el-tab-pane>
        <el-tab-pane label="账号管理" name="/manager/account"></el-tab-pane>
        <el-tab-pane label="站点设置" name="/manager/site"></el-tab-pane>
<!--        <el-tab-pane label="远程附件" name="/manager/attachment"></el-tab-pane>-->
<!--        <el-tab-pane label="微公众号" name="/manager/wx-official"></el-tab-pane>-->
<!--        <el-tab-pane label="微信支付" name="/manager/wx-pay"></el-tab-pane>-->
<!--        <el-tab-pane label="阿里支付" name="/manager/ali-pay"></el-tab-pane>-->
<!--        <el-tab-pane label="短信配置" name="/manager/sns"></el-tab-pane>-->
      </el-tabs>
      <div style="padding: 1rem;width: 70rem;min-height: 41rem;background-color: #FFFFFF">
        <router-view />
      </div>
    </div>
  </y_layout>
</template>

<script>
import y_layout from "@/components/account/y_layout";

export default {
  name: "index",
  components:{
    y_layout
  },
  data(){
    return{
      active:""
    }
  },
  created() {
    this.onRouteChanged()
  },
  methods: {
    onRouteChanged() {
      let that = this;
      that.active = this.$route.path;
    }
  },
  watch: {
    '$route': 'onRouteChanged',
    active(e){
      this.$router.push(e);
    }
  }
}
</script>

<style>
.el-tabs--border-card {
  border-right: unset;
}
.el-tabs--left.el-tabs--border-card .el-tabs__header.is-left {
  border-right: unset;
}
.el-tabs--left .el-tabs__header.is-left {
  float: left;
  margin-bottom: 0;
  margin-right: 0;
}
div.el-tabs__content{
  display: none;
}
.el-tabs--border-card {
   border: unset;
}
</style>